//--------------------------------------
// Five columns
//--------------------------------------
// Based on http://stackoverflow.com/a/21142019/167815

.col-xs-5ths        { @include make-xs-column($grid-columns / 5);        }
.col-xs-push-5ths   { @include make-xs-column-push($grid-columns / 5);   }
.col-xs-pull-5ths   { @include make-xs-column-pull($grid-columns / 5);   }
.col-xs-offset-5ths { @include make-xs-column-offset($grid-columns / 5); }

.col-sm-5ths        { @include make-sm-column($grid-columns / 5);        }
.col-sm-push-5ths   { @include make-sm-column-push($grid-columns / 5);   }
.col-sm-pull-5ths   { @include make-sm-column-pull($grid-columns / 5);   }
.col-sm-offset-5ths { @include make-sm-column-offset($grid-columns / 5); }

.col-md-5ths        { @include make-md-column($grid-columns / 5);        }
.col-md-push-5ths   { @include make-md-column-push($grid-columns / 5);   }
.col-md-pull-5ths   { @include make-md-column-pull($grid-columns / 5);   }
.col-md-offset-5ths { @include make-md-column-offset($grid-columns / 5); }

.col-lg-5ths        { @include make-lg-column($grid-columns / 5);        }
.col-lg-push-5ths   { @include make-lg-column-push($grid-columns / 5);   }
.col-lg-pull-5ths   { @include make-lg-column-pull($grid-columns / 5);   }
.col-lg-offset-5ths { @include make-lg-column-offset($grid-columns / 5); }

//--------------------------------------
// Maximum content width
//--------------------------------------

.content-width {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
}

//--------------------------------------
// Remove the outer gutters
//--------------------------------------

.no-outer-gutters {
    padding-left: 0;
    padding-right: 0;
}

.no-outer-gutters > .row {
    margin-left: 0;
    margin-right: 0;
}

.content-width.no-outer-gutters {
    // Reduce the maximum width to match the content with gutters
    max-width: 1200px - $grid-gutter-width;
}

//--------------------------------------
// Remove the inner gutters
//--------------------------------------

.no-inner-gutters .row {
    padding-left: 15px;
    padding-right: 15px;
}

.no-inner-gutters > .row > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
}

//--------------------------------------
// No gutters at all
//--------------------------------------

.no-gutters {
    @extend .no-outer-gutters;
    // We don't want padding on the row in the case, so can't just extend it:
    // @extend .no-inner-gutters;
}

.no-gutters > .row > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
}

//--------------------------------------
// Space between rows
//--------------------------------------

@mixin space-above-below($size-sm, $size-md, $size-lg) {
    .space-above-sm { margin-top:    $size-sm; }
    .space-below-sm { margin-bottom: $size-sm; }
    .space-above-md { margin-top:    $size-md; }
    .space-below-md { margin-bottom: $size-md; }
    .space-above    { margin-top:    $size-lg; }
    .space-below    { margin-bottom: $size-lg; }
}

@include space-above-below(5px, 10px, 20px);

@media (min-width: $screen-sm-min) {
    @include space-above-below(10px, 20px, 30px);
}

@media (min-width: $screen-md-min) {
    @include space-above-below(10px, 20px, 50px);
}
