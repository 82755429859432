//==============================================================================
// Override defaults
//==============================================================================

.alert-link {
    // Make the links easier to see
    text-decoration: underline;
}

.no-js .alert-dismissible .close {
    display: none;
}

//==============================================================================
// Full width
//==============================================================================

// Used for notifications at the very top of the page (e.g. Cookies)
// Could also be used between sections, e.g. below the navigation (but this is untested!)
.alert-full-width {
    // Remove the border
    border: none;
    // Remove the counded corners
    border-radius: 0;
    // Remove the padding (need to insert a .content-width div inside instead)
    padding-left: 0;
    padding-right: 0;
}

.alert-full-width .alert-dismissible .close {
    right: -36px;
}

//==============================================================================
// Small alert
//==============================================================================

// Used for the cookies notification - small text and reduced padding
.alert-small {
    font-size: 14px;
    padding-bottom: 5px;
    padding-top: 5px;
}

//==============================================================================
// Colour variations
//==============================================================================

// Usage: @include alert-variant($background-color, $border-color, $text-color);

//--------------------------------------
// Blues
//--------------------------------------

.alert-light-blue {
    @include alert-variant($light-blue, transparent, white);
}

.alert-oxford-blue {
    @include alert-variant($oxford-blue, transparent, white);
}

.alert-dark-blue {
    @include alert-variant($dark-blue, transparent, white);
}

//--------------------------------------
// Browns
//--------------------------------------

.alert-light-brown {
    @include alert-variant($light-brown, transparent, $dark-blue);
}

.alert-medium-brown {
    @include alert-variant($medium-brown, transparent, $dark-blue);
}

.alert-dark-brown {
    @include alert-variant($dark-brown, transparent, white);
}

//--------------------------------------
// White
//--------------------------------------

.alert-white {
    @include alert-variant(white, transparent, $dark-blue);
}

//--------------------------------------
// Shared
//--------------------------------------

.alert-light-blue,
.alert-oxford-blue,
.alert-dark-blue,
.alert-dark-brown {
    // These all have a white font, so the close buttons need changing to
    // match, and the links look better as pure white
    .close {
        color: white;
        opacity: 0.8;
    }

    .close:hover,
    .close:focus {
        color: white;
        opacity: 1;
    }

    .alert-link {
        color: white;
    }
}
