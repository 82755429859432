//==============================================================================
// Common
//==============================================================================

// Note: This is different enough from the Bootstrap .nav classes that I've not reused them
.side-menu {
    //--------------------------------------
    // Lists
    //--------------------------------------

    // Standard list styling
    &,
    ul {
        @extend .list-unstyled;
        margin-bottom: 0;
    }

    // Add indent and margins to submenus
    ul {
        margin-bottom: 10px;
        margin-top: 7px;
        padding-left: 20px;
    }

    // Font sizes
    font-size: 18px;
    line-height: 1.1;

    ul {
        font-size: 16px;
    }

    //--------------------------------------
    // List items
    //--------------------------------------

    li {
        padding: 2px 0;
    }

    // The second level has borders between links
    > li > ul > li {
        padding: 6px 0;
    }

    > li > ul > li + li {
        border-top: 1px solid $light-brown;
        padding: 6px 0;
    }

    > li > ul > li + li.side-menu-heading {
        border-top: none;
    }

    //--------------------------------------
    // Links
    //--------------------------------------

    a {
        font-weight: normal;
    }

    // Dark blue for the top level instead of light blue
    > li > a {
        color: $oxford-blue;
    }

    > li > a:hover,
    > li > a:focus {
        color: $oxford-blue-hover;
    }

    // Bold for active menu items
    .active > a {
        font-weight: bold;
    }
}

//--------------------------------------
// Headings
//--------------------------------------

// Note: These are also used in the mobile navigation dropdown, because they
// are copied there by the JavaScript

// Common
.side-menu-heading {
    color: $dark-brown;
    cursor: default;
    font-weight: bold;
    margin-top: 9px;
    text-transform: uppercase;
}

// No extra padding needed if it's the first thing in the list
.side-menu-heading:first-child {
    padding-top: 0;
}

// Headings at the top or second level are bold - lower levels are not
.side-menu ul ul .side-menu-heading {
    font-weight: normal;
}


//================================================================================
// Mobile/tablet styles
//================================================================================

@media (max-width: $screen-sm-max) {
    .side-menu-col {
        // White background
        background: white;
        border-top: 1px solid $light-brown;
        border-bottom: 1px solid $light-brown;
        padding-bottom: 15px;
        margin-top: 20px;
    }

    .side-menu-title {
        // Style like a panel title
        background-color: $dark-brown;
        color: #fff;
        font-size: 30px;
        font-weight: normal;
        // Overlap the column padding - stretch to the screen edge
        margin: -1px -15px 0 -15px;
        padding: 7px 15px;
    }

    .side-menu-title a,
    .side-menu-title a:hover,
    .side-menu-title a:focus {
        color: #fff;
        font-weight: normal;
    }

    .side-menu {
        margin-top: 15px;
    }
}

@media (min-width: $screen-md-min) {
    .side-menu-title {
        // Hide title on desktop version
        display: none;
    }
}

// Hide the placeholder until the JavaScript has run
.side-menu-copy-placeholder {
    display: none;
}
