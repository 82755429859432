// The .heroine box is an alternate type of .hero box
// I could equally have called it .hero2 - but I like this name better ;-)

//==============================================================================
// Image
//==============================================================================

.heroine-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include background-size-polyfill;
    min-height: 290px;
    padding: 5px 35px 17px 35px;
}

.heroine-has-caption .heroine-image {
    min-height: 210px;
}

.heroine-image-text-wrapper {
    margin: 0 auto;
    max-width: 735px;
}

.heroine-image-text {
    font-size: 30px;
    line-height: 1.2;
    max-width: 365px;
}

//--------------------------------------
// Colour variations
//--------------------------------------

// Choose the background colour that matches the image tint
@mixin cs-heroine-variant($text-color, $background-color) {
    background-color: $background-color;
    color: $text-color;
}

.heroine-image-light-blue   { @include cs-heroine-variant(white,        $light-blue  ); }
.heroine-image-oxford-blue  { @include cs-heroine-variant(white,        $oxford-blue ); }
.heroine-image-dark-blue    { @include cs-heroine-variant(white,        $dark-blue   ); }
.heroine-image-light-brown  { @include cs-heroine-variant($dark-blue,   $light-brown ); }
.heroine-image-medium-brown { @include cs-heroine-variant(white,        $medium-brown); }
.heroine-image-dark-brown   { @include cs-heroine-variant(white,        $dark-brown  ); }
.heroine-image-white        { @include cs-heroine-variant($oxford-blue, white        ); }

//==============================================================================
// Caption
//==============================================================================

.heroine-caption {
    background: white;
    padding: 16px 35px;
    position: relative;
}

.heroine-caption-text-wrapper {
    margin: 0 auto;
    max-width: 735px;
    padding-right: 30px;
}

.heroine-caption-text {
    font-size: 18px;
    line-height: 1.33;
}

.heroine-caption-text-collapsed {
    max-height: 2 * 18px * 1.33;
    overflow: hidden;
}

.heroine-caption-text p {
    margin-bottom: 12px;
}

.heroine-caption-text p:last-child {
    margin-bottom: 0;
}

.heroine-caption-toggle {
    font-size: 15px;
    height: 30px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 20px;
    width: 30px;
}

.heroine-caption-toggle .glyphicon-plus {
    // This makes it appear centered
    padding-left: 2px;
}
