//--------------------------------------
// Container
//--------------------------------------

.site-footer {
    background: $oxford-blue;
    border-top: 4px solid $light-brown;
    color: white;
    padding: 20px 0 10px 0;
    text-align: center;

    @media screen and (min-width: $screen-sm-min) {
        text-align: left;
    }
}

//--------------------------------------
// Common
//--------------------------------------

.site-footer ul {
    @extend .list-unstyled;
}

.site-footer a {
    color: white;
    font-weight: normal;
}

//--------------------------------------
// Links
//--------------------------------------

.site-footer-links {
    @media screen and (min-width: $screen-sm-min) {
        margin-top: 13px;

        li {
            float: left;
            width: 180px;
        }

        li:nth-child(odd) {
            clear: left;
        }
    }
}

//--------------------------------------
// Social media links
//--------------------------------------

.site-footer-social {
    margin: 25px auto;
    width: 174px;

    @media screen and (min-width: $screen-sm-min) {
        float: right;
        margin-top: 30px;
        width: auto;
    }

    li {
        float: left;
        margin-right: 10px;
    }

    li:last-child {
        margin-right: 0;
    }
}

//--------------------------------------
// Copyright
//--------------------------------------

.site-footer-copyright {
    margin-bottom: 0;
    text-align: center;

    @media screen and (min-width: $screen-sm-min) {
        margin-top: 40px;
    }
}

//--------------------------------------
// URL
//--------------------------------------

.site-footer-url {
    display: none;
    font-size: 13px;
    margin-top: 2px;

    @media print {
        display: block;
    }
}

//--------------------------------------
// Tooltips
//--------------------------------------

.site-footer .tooltip-inner {
    background: white;
    color: $light-blue;
}

.site-footer .left .tooltip-arrow {
    border-left-color: white;
}
