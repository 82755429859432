.breadcrumb {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: bold;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 32px;
    }

    // Arrows
    > li + li:before {
        font-size: 13px;
    }

    // Links
    a {
        color: $dark-brown;
    }

    a:hover,
    a:focus {
        color: $dark-brown-hover;
    }

    // Current page title
    > .active {
        color: $dark-brown;
        cursor: default;
    }
}
