// Note: This is different enough from the Bootstrap default classes that I've
// not used their code at all - but it's based on it, so should be compatible

.thumbnail {
    @include transition(border .2s ease-in-out);
    display: block;
    line-height: $line-height-base;
    margin-bottom: $line-height-computed;
}

.thumbnail > img,
.thumbnail a > img {
    @extend .img-responsive;
    background: $light-brown;
    border: 4px solid $medium-brown;
}

.thumbnail .caption {
    color: $dark-blue;
    display: block;
    font-weight: bold;
    padding-top: 3px;
}

a.thumbnail .caption {
    color: $light-blue;
}

// Hover effects
a.thumbnail:hover,
a.thumbnail:focus {
    // No underline
    text-decoration: none;
}

a.thumbnail:hover > img,
a.thumbnail:focus > img,
a.thumbnail.active > img {
    // Change border color
    border-color: $light-blue;
}

// Centre the image and caption
.thumbnail-center {
    text-align: center;

    > img,
    a > img {
        margin-left: auto;
        margin-right: auto;
    }
}

// Right-align the image and caption
.thumbnail-right {
    text-align: right;

    > img,
    a > img {
        margin-left: auto;
    }
}
