.print-title {
    display: none;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 10px;
}

@media print {
    // Show these elements
    .print-title {
        display: block;
    }

    // Hide these elements
    .no-print,
    .back-to-top,
    .entry-points,
    .featured-list-button,
    .hero-image,
    .mobile-menu,
    .panel-heading .btn,
    .side-menu-col,
    .site-header,
    .slideshow {
        display: none !important;
    }

    // Remove some of the URLs that Bootstrap adds to all links
    a.no-print-url:after,
    .breadcrumb a:after,
    .site-footer-copyright a:after {
        content: none;
    }

    // Remove padding from content areas
    .content-width {
        padding-left: 0;
        padding-right: 0;
    }

    // Remove fixed heights from equal height elements - since they won't be the
    // same height when printed as they are on screen
    .equal-height {
        height: auto !important;
    }

    // Remove border from footer
    .site-footer {
        border: none;
        padding: 0;
        margin-top: 15px;
    }
}
