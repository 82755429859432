.featured-list {
    background: white;
    font-size: 18px;
}

//--------------------------------------
// Header / Title
//--------------------------------------

.featured-list-header {
    text-align: center;
}

.featured-list-title {
    color: $dark-brown;
    font-size: 30px;
    text-align: center;
}

.featured-list-title a {
    color: $dark-brown;
}

.featured-list-title a:hover,
.featured-list-title a:focus {
    color: $dark-brown-hover;
}

@media (min-width: $screen-sm-min) {
    .featured-list-header {
        // Add padding to ensure there's sufficient space for the button
        // This is rather unscientific since the button size may vary
        // But I can't think of a better approach since the title must be centred
        padding: 0 11em;
        position: relative;
    }

    .featured-list-button {
        position: absolute;
        // I chose 30px here because it happens to look best in the Latest
        // Vacancies box on the homepage (desktop view) - no other reason
        right: 30px;
        top: 0;
    }
}

//--------------------------------------
// Columns
//--------------------------------------

// Common
.featured-list-col {
    padding-bottom: 67px;
    position: relative;
    text-align: center;
}

// One column
.featured-list-col + .featured-list-col {
    // Line between all rows
    border-top: 2px solid #f3f1ec;
}

// Two columns
@media (min-width: $screen-sm-min) {
    .featured-list-col:first-child + .featured-list-col {
        // No line above the second box (top of the second column)
        border-top: none;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .featured-list-col:nth-child(2n+2) {
        // Line between columns
        border-left: 2px solid #f3f1ec;
    }
}

// Four columns
@media (min-width: $screen-md-min) {
    .featured-list-col:first-child + .featured-list-col + .featured-list-col,
    .featured-list-col:first-child + .featured-list-col + .featured-list-col + .featured-list-col {
        // No line above the third and fourth boxes
        border-top: none;
    }

    .featured-list-col:nth-child(4n+2),
    .featured-list-col:nth-child(4n+3),
    .featured-list-col:nth-child(4n+4) {
        // Line between columns
        border-left: 2px solid #f3f1ec;
    }
}

//--------------------------------------
// Text
//--------------------------------------

.featured-list {
    font-size: 16px;
}

.featured-list h3 {
    color: $dark-blue;
    font-size: 24px;
    font-weight: bold;
}

//--------------------------------------
// Button
//--------------------------------------

.featured-list-btn {
    bottom: 29px;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
}
