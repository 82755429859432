.page-title {
    // Bigger font size for subtitle / jump links (main title should be a <h1> though)
    font-size: 24px;
    font-weight: bold;
    // Big gap below the title
    margin-bottom: 28px;
}

.page-title h1 {
    // Remove the gap at the top of the column
    margin-top: 0;
}

@media (min-width: $screen-md-min) {
    .page-title {
        // Big gap below the title
        margin-bottom: 42px;
    }
}
