//==============================================================================
// Colour palette
//==============================================================================

// Blues
$light-blue:  #4270a9;
$oxford-blue: #002147;
$dark-blue:   #353c47;

// Browns
$light-brown:  #e0ded9;
$medium-brown: #c3bdb7;
$dark-brown:   #a19c96;

// Light grey for the background only
// (it's arguably a little brown too, but I've run out of names!)
$light-grey: #f3f1ec;

// Hover colours
$light-blue-hover:   darken($light-blue,   12%);
$oxford-blue-hover:  darken($oxford-blue,  12%);
$dark-blue-hover:    darken($dark-blue,    10%);
$light-brown-hover:  darken($light-brown,  10%);
$medium-brown-hover: darken($medium-brown, 10%);
$dark-brown-hover:   darken($dark-brown,   15%);
$white-hover:        lighten($light-brown, 10%);

//==============================================================================
// Bootstrap settings
//==============================================================================

// Common
$font-family-sans-serif: 'PT Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-base: 16px;
$text-color: $dark-blue;

$font-size-large: 24px;
// $padding-large-horizontal: 30px;

// Background
$body-bg: $light-grey;

// Generic / base colours
$brand-primary: $oxford-blue;

// Headings
$font-size-h1: 48px;
$headings-font-weight: bold;

// Links
$link-color: $light-blue;
$link-hover-color: darken($light-blue, 15%);

// Tooltips
$tooltip-bg: $oxford-blue;
$tooltip-color: white;
$tooltip-opacity: 1;

// Breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-padding-horizontal: 0;
$breadcrumb-padding-vertical: 0;
$breadcrumb-separator: '>';

// Buttons
$btn-border-radius-base: 5px;

// Dropdown menus
$dropdown-link-color:        $light-blue;
$dropdown-link-hover-color:  white;
$dropdown-link-hover-bg:     $light-blue;
$dropdown-link-active-color: $light-blue; // We'll make this bold instead
$dropdown-link-active-bg:    white;

// Navbars
$navbar-default-bg: white;
$navbar-default-border: $light-brown;
$navbar-default-link-color: $light-blue;
$navbar-default-link-hover-color: $dark-blue;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: $dark-blue;
$navbar-default-link-active-bg: transparent;
$navbar-default-link-disabled-color: $light-brown;
$navbar-default-link-disabled-bg: transparent;

// Panels
$panel-body-padding:    25px;
$panel-heading-padding: 16px 25px;

// Forms
$input-border-focus: $oxford-blue;
$input-height-large: 44px;

// Breakpoints
//                      // xs = 1 column
$screen-sm-min: 700px;  // sm = 2 columns
$screen-md-min: 950px;  // md = 4 columns
$screen-lg-min: 1200px; // xs = 4/5 columns (full desktop)

// Paths
$icon-font-path: '/bower_components/bootstrap-sass-official/assets/fonts/bootstrap/';

//==============================================================================
// Font Awesome settings
//==============================================================================

$fa-font-path: '/bower_components/fontawesome/fonts';
