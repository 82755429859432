//==============================================================================
// Override defaults
//==============================================================================

p {
    // Increase margin from the default of 11px
    margin: 0 0 16px 0;
}

//==============================================================================
// Colour variations
//==============================================================================

@mixin cs-text-variant($color) {
    color: $color;

    .underlined-line {
        background: $color;
    }
}

.text-light-blue   { @include cs-text-variant($light-blue);   }
.text-oxford-blue  { @include cs-text-variant($oxford-blue);  }
.text-dark-blue    { @include cs-text-variant($dark-blue);    }
.text-light-brown  { @include cs-text-variant($light-brown);  }
.text-medium-brown { @include cs-text-variant($medium-brown); }
.text-dark-brown   { @include cs-text-variant($dark-brown);   }
.text-white        { @include cs-text-variant(white);         }

//==============================================================================
// Other variations
//==============================================================================

//--------------------------------------
// Intro
//--------------------------------------

// Intro text is simply bold, but use a class anyway to allow future changes
.intro {
    font-weight: bold;
}

//--------------------------------------
// Large text
//--------------------------------------

.lg-text {
    font-size: 24px;
}

.lg-text small,
.lg-text .small {
    font-size: percentage(15px / 24px);
}
