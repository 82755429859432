.date-icon {
    background: $oxford-blue;
    color: white;
    display: block;
    font-weight: normal; // Undo bold in ".media-list-links .media"
    margin-right: 15px;
    padding: 3px 0;
    text-align: center;
    width: 44px;
}

a.date-icon:hover,
a.date-icon:focus {
    background: $light-blue;
    color: white !important; // Override ".media-list-links .media a:hover"
    text-decoration: none;
}

.date-icon .day {
    display: block;
    font-size: 22px;
    line-height: 1;
}

.date-icon .month {
    display: block;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
}
