//==============================================================================
// Override defaults
//==============================================================================

.btn {
    border-width: 2px;
    font-weight: bold;
    // Adjust padding to allow for the extra border, so it matches input heights
    padding: ($padding-base-vertical - 1px) ($padding-base-horizontal - 1px);
}

.btn-group {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        // Adjust the overlap of buttons within a group to accommodate the bigger borders
        margin-left: -2px;
    }
}

//==============================================================================
// Colour variations
//==============================================================================

// This is a custom mixin with no hover styles, so they can be set separately
@mixin cs-button-variant($text, $background: null, $border: null) {
    color: $text;
    background-color: $background;
    border-color: $border;

    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $text;
    }
}

// This is separate so the hover styles can be overridden separately
@mixin cs-button-hover($text, $background: null, $border: null) {
    &:hover,
    &:focus,
    &.focus {
        color: $text;
        background-color: $background;
        border-color: $border;
    }

    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $text;
        background-color: $background;
        border-color: $border;

        &:hover,
        &:focus,
        &.focus {
            color: $text;
            background-color: $background;
            border-color: $border;
        }
    }
}

//--------------------------------------
// Solid colours
//--------------------------------------

.btn-light-blue {
    @include cs-button-variant(white,             $light-blue        );
    @include cs-button-hover  (white,             $light-blue-hover  );
}

.btn-oxford-blue {
    @include cs-button-variant(white,             $oxford-blue       );
    @include cs-button-hover  (white,             $oxford-blue-hover );
}

.btn-dark-blue {
    @include cs-button-variant(white,             $dark-blue         );
    @include cs-button-hover  (white,             $dark-blue-hover   );
}

.btn-light-brown {
    @include cs-button-variant($dark-blue,        $light-brown       );
    @include cs-button-hover  ($dark-blue,        $light-brown-hover );
}

.btn-medium-brown {
    @include cs-button-variant(white,             $medium-brown      );
    @include cs-button-hover  (white,             $medium-brown-hover);
}

.btn-dark-brown {
    @include cs-button-variant(white,             $dark-brown        );
    @include cs-button-hover  (white,             $dark-brown-hover  );
}

.btn-white {
    @include cs-button-variant($light-blue,       white              );
    @include cs-button-hover  ($light-blue-hover, $white-hover       );
}

//--------------------------------------
// Coloured outlines
//--------------------------------------

.btn-light-blue-outline {
    @include cs-button-variant($light-blue,         null,        $light-blue        );
    @include cs-button-hover  ($light-blue-hover,   $white-hover, $light-blue-hover  );
}

.btn-oxford-blue-outline {
    @include cs-button-variant($oxford-blue,        null,        $oxford-blue       );
    @include cs-button-hover  ($oxford-blue-hover,  $white-hover, $oxford-blue-hover );
}

.btn-dark-blue-outline {
    @include cs-button-variant($dark-blue,          null,        $dark-blue         );
    @include cs-button-hover  ($dark-blue-hover,    $white-hover, $dark-blue-hover   );
}

.btn-light-brown-outline {
    @include cs-button-variant($light-brown,        null,        $light-brown       );
    @include cs-button-hover  ($light-brown-hover,  $white-hover, $light-brown-hover );
}

.btn-medium-brown-outline {
    @include cs-button-variant($medium-brown,       null,        $medium-brown      );
    @include cs-button-hover  ($medium-brown-hover, $white-hover, $medium-brown-hover);
}

.btn-dark-brown-outline {
    @include cs-button-variant($dark-brown,         null,        $dark-brown        );
    @include cs-button-hover  ($dark-brown-hover,   $white-hover, $dark-brown-hover  );
}

//--------------------------------------
// White outlines
//--------------------------------------

.btn-white-outline-on-light-blue {
    @include cs-button-variant(white, $light-blue,         white);
    @include cs-button-hover  (white, $light-blue-hover,   white);
}

.btn-white-outline-on-oxford-blue {
    @include cs-button-variant(white, $oxford-blue,        white);
    @include cs-button-hover  (white, $oxford-blue-hover,  white);
}

.btn-white-outline-on-dark-blue {
    @include cs-button-variant(white, $dark-blue,          white);
    @include cs-button-hover  (white, $dark-blue-hover,    white);
}

.btn-white-outline-on-light-brown {
    @include cs-button-variant(white, $light-brown,        white);
    @include cs-button-hover  (white, $light-brown-hover,  white);
}

.btn-white-outline-on-medium-brown {
    @include cs-button-variant(white, $medium-brown,       white);
    @include cs-button-hover  (white, $medium-brown-hover, white);
}

.btn-white-outline-on-dark-brown {
    @include cs-button-variant(white, $dark-brown,         white);
    @include cs-button-hover  (white, $dark-brown-hover,   white);
}

//--------------------------------------
// White backgrounds
//--------------------------------------

.btn-light-blue-on-white {
    @include cs-button-variant($light-blue,         white);
    @include cs-button-hover  ($light-blue-hover,   $white-hover);
}

.btn-oxford-blue-on-white {
    @include cs-button-variant($oxford-blue,        white);
    @include cs-button-hover  ($oxford-blue-hover,  $white-hover);
}

.btn-dark-blue-on-white {
    @include cs-button-variant($dark-blue,          white);
    @include cs-button-hover  ($dark-blue-hover,    $white-hover);
}

.btn-light-brown-on-white {
    @include cs-button-variant($light-brown,        white);
    @include cs-button-hover  ($light-brown-hover,  $white-hover);
}

.btn-medium-brown-on-white {
    @include cs-button-variant($medium-brown,       white);
    @include cs-button-hover  ($medium-brown-hover, $white-hover);
}

.btn-dark-brown-on-white {
    @include cs-button-variant($dark-brown,         white);
    @include cs-button-hover  ($dark-brown-hover,   $white-hover);
}

//==============================================================================
// Other variations
//==============================================================================

// Same as the default, but thinner (unlike .btn-sm which has a small font)
.btn-thin {
    @include button-size(1px, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
}

//----------------------------------------
// Icon
//----------------------------------------

.btn-icon {
    padding: 0;
}

// May have text at certain screen sizes, and that needs padding
.btn-icon-text {
    padding-right: $padding-base-horizontal;
}
