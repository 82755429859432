//==============================================================================
// Override defaults
//==============================================================================

//--------------------------------------
// Large input groups
//--------------------------------------

// Reduce the padding so the text lines up correctly
// This may be related: https://github.com/twbs/bootstrap/issues/16824
// But the fix applied there didn't work for me...
@media (min-width: $screen-sm-min) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 5px;
    }
}

// Increase the space between rows a little to give more balance
.form-group-lg {
    margin-bottom: 18px;
}

// Bootstrap only changes the font size in .form-horizontal by default
.form-group-lg,
.form-group-lg .control-label {
    font-size: $font-size-large;
}

// Reduce the font size, but keep the same heights
.form-group-lg .form-control {
    font-size: 18px;
}

// Adjust inline checkboxes & radio buttons
.form-group-lg {
    input[type="radio"],
    input[type="checkbox"] {
        margin-top: 10px;
    }
}

//==============================================================================
// Colour variations
//==============================================================================

@mixin cs-form-variant($text-color, $border-color) {
    .form-control {
        border: 2px solid $border-color;
        padding: ($padding-base-vertical - 1px) ($padding-base-horizontal - 1px);
    }

    .input-group-addon {
        border-color: $border-color;
        background: $border-color;
        color: $text-color;
    }
}

//--------------------------------------
// Bordered with white backgrounds
//--------------------------------------

.form-light-blue-on-white   { @include cs-form-variant(white,       $light-blue);   }
.form-oxford-blue-on-white  { @include cs-form-variant(white,       $oxford-blue);  }
.form-dark-blue-on-white    { @include cs-form-variant(white,       $dark-blue);    }
.form-light-brown-on-white  { @include cs-form-variant($dark-blue,  $light-brown);  }
.form-medium-brown-on-white { @include cs-form-variant(white,       $medium-brown); }
.form-dark-brown-on-white   { @include cs-form-variant(white,       $dark-brown);   }
.form-white-on-white        { @include cs-form-variant($light-blue, white);         }

//==============================================================================
// Other variations
//==============================================================================

//--------------------------------------
// Horizontal with left-aligned labels
//--------------------------------------

@media (min-width: $screen-sm-min) {
    .form-horizontal-left .control-label {
        text-align: left;
    }
}

//--------------------------------------
// Extra spacers
//--------------------------------------

// Even bigger space for separating element groups slightly
.form-group-space-above {
    margin-top: 28px;
}

.form-group-space-below {
    margin-bottom: 28px;
}

// Additional around in large groups
.form-group-space-above.form-group-lg {
    margin-top: 30px;
}

.form-group-space-below.form-group-lg {
    margin-bottom: 30px;
}

//--------------------------------------
// Remove borders
//--------------------------------------

// Use this on a dark background - e.g. blue panels
.form-control-no-border {
    // Change the border colour, don't remove it, to allow the :focus state to keep working
    border-color: transparent;
    box-shadow: none;
}
