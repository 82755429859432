//==============================================================================
// Base styles
//==============================================================================

.hero {
    @include clearfix;
    position: relative;
}

.hero-text {
    font-size: 24px;
    margin: 0 auto 22px auto;
    text-align: center;
}

.hero-text-has-btn {
    padding-bottom: 28px;
}

.hero-btn {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

    .btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.hero-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include background-size-polyfill;
    min-height: 200px;
}

@media (min-width: $screen-sm-min) {
    .hero-text {
        text-align: left;
        margin-bottom: 25px;
        max-width: 350px;
    }

    .hero-btn,
    .hero-btn .btn {
        left: auto;
        right: auto;
    }
}

//==============================================================================
// Colour variations
//==============================================================================

@mixin cs-hero-variant($text-color, $background-color) {
    background: $background-color;
    color: $text-color;
}

.hero-light-blue   { @include cs-hero-variant(white,        $light-blue  ); }
.hero-oxford-blue  { @include cs-hero-variant(white,        $oxford-blue ); }
.hero-dark-blue    { @include cs-hero-variant(white,        $dark-blue   ); }
.hero-light-brown  { @include cs-hero-variant($dark-blue,   $light-brown ); }
.hero-medium-brown { @include cs-hero-variant(white,        $medium-brown); }
.hero-dark-brown   { @include cs-hero-variant(white,        $dark-brown  ); }
.hero-white        { @include cs-hero-variant($oxford-blue, white        ); }
