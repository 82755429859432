//==============================================================================
// Override defaults
//==============================================================================

.panel {
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.panel-heading {
    border: none;
    border-radius: 0;
}

.panel-heading .panel-title {
    font-size: 30px;
}

.panel-heading .panel-title-small {
    // Alternative title font
    font-size: 23px;
}

.panel-heading .btn {
    padding: 4px 20px;
}

.panel-body {
    font-size: 18px;
}

//==============================================================================
// Additional styles
//==============================================================================

// This is a custom class, not in the Bootstrap core, but similar in purpose to
// .alert-link. (We can't style all "a" tags because that overrides buttons.)
.panel-link {
    font-weight: inherit;
    text-decoration: underline;
}

.panel .plain-text:hover,
.panel .plain-text:focus {
    // Don't change the plain text link colour on hover
    color: inherit;
}

// This is a subheading
.panel-subheading {
    font-size: 23px;
    margin: 25px 0 5px 0;
}

//==============================================================================
// Colour variations
//==============================================================================

@mixin cs-panel-variant($heading-text-color, $heading-bg-color, $body-text-color, $body-bg-color, $border-color: null, $hover-text-color: null) {
    // Usage: @include panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border)
    @include panel-variant(null, $heading-text-color, $heading-bg-color, null);

    background-color: $body-bg-color;
    color: $body-text-color;

    // Links the same colour as the text
    .panel-link {
        color: $body-text-color;
    }

    .panel-link:hover,
    .panel-link:focus {
        @if $hover-text-color {
            color: $hover-text-color;
        } @elseif $body-text-color {
            color: darken($body-text-color, 15%);
        }
    }

    &.panel-links .panel-body a,
    &.panel-clickable a,
    &.panel-clickable a:hover,
    &.panel-clickable a:focus {
        color: inherit;
    }

    // Border?
    @if $border-color {
        border: 2px solid $border-color;
    }

    // If it's clickable, style it on hover (<a> tags should cover it all)
    &.panel-clickable:hover,
    &.panel-clickable:focus {
        background-color: darken($body-bg-color, 8%);
        border-color: $border-color and darken($border-color, 8%);

        .panel-heading {
            background-color: darken($heading-bg-color, 8%);
        }

        a {
            text-decoration: none;
        }
    }
}

//--------------------------------------
// Solid colours
//--------------------------------------

.panel-light-blue   { @include cs-panel-variant(white,         $light-blue,   white,       $light-blue,  null, $light-brown); }
.panel-oxford-blue  { @include cs-panel-variant(white,         $oxford-blue,  white,       $oxford-blue, null, $dark-brown ); }
.panel-dark-blue    { @include cs-panel-variant($medium-brown, $dark-blue,    white,       $dark-blue,   null, $dark-brown ); }
.panel-light-brown  { @include cs-panel-variant($dark-blue,    $light-brown,  $dark-blue,  $light-brown                    ); }
.panel-medium-brown { @include cs-panel-variant(white,         $medium-brown, white,       $medium-brown                   ); }
.panel-dark-brown   { @include cs-panel-variant(white,         $dark-brown,   white,       $dark-brown                     ); }
.panel-white        { @include cs-panel-variant($light-blue,   white,         $light-blue, white                           ); }

//--------------------------------------
// Heading only, transparent body
//--------------------------------------

.panel-light-blue-heading   { @include cs-panel-variant(white,       $light-blue,   null, transparent); }
.panel-oxford-blue-heading  { @include cs-panel-variant(white,       $oxford-blue,  null, transparent); }
.panel-dark-blue-heading    { @include cs-panel-variant(white,       $dark-blue,    null, transparent); }
.panel-light-brown-heading  { @include cs-panel-variant($dark-blue,  $light-brown,  null, transparent); }
.panel-medium-brown-heading { @include cs-panel-variant(white,       $medium-brown, null, transparent); }
.panel-dark-brown-heading   { @include cs-panel-variant(white,       $dark-brown,   null, transparent); }
.panel-white-heading        { @include cs-panel-variant($light-blue, white,         null, transparent); }

//--------------------------------------
// Heading with white body
//--------------------------------------

.panel-light-blue-and-white   { @include cs-panel-variant(white,       $light-blue,   $dark-blue, white); }
.panel-oxford-blue-and-white  { @include cs-panel-variant(white,       $oxford-blue,  $dark-blue, white); }
.panel-dark-blue-and-white    { @include cs-panel-variant(white,       $dark-blue,    $dark-blue, white); }
.panel-light-brown-and-white  { @include cs-panel-variant($dark-blue,  $light-brown,  $dark-blue, white); }
.panel-medium-brown-and-white { @include cs-panel-variant(white,       $medium-brown, $dark-blue, white); }
.panel-dark-brown-and-white   { @include cs-panel-variant(white,       $dark-brown,   $dark-blue, white); }

//--------------------------------------
// Coloured text, white background
//--------------------------------------

.panel-light-blue-on-white   { @include cs-panel-variant($light-blue,   white, $light-blue,   white); }
.panel-oxford-blue-on-white  { @include cs-panel-variant($oxford-blue,  white, $oxford-blue,  white); }
.panel-dark-blue-on-white    { @include cs-panel-variant($dark-blue,    white, $dark-blue,    white); }
.panel-light-brown-on-white  { @include cs-panel-variant($light-brown,  white, $light-brown,  white); }
.panel-medium-brown-on-white { @include cs-panel-variant($medium-brown, white, $medium-brown, white); }
.panel-dark-brown-on-white   { @include cs-panel-variant($dark-brown,   white, $dark-brown,   white); }

//--------------------------------------
// Outline
//--------------------------------------

.panel-light-blue-outline   { @include cs-panel-variant($light-blue,   transparent, $light-blue,   transparent, $light-blue);   }
.panel-oxford-blue-outline  { @include cs-panel-variant($oxford-blue,  transparent, $oxford-blue,  transparent, $oxford-blue);  }
.panel-dark-blue-outline    { @include cs-panel-variant($dark-blue,    transparent, $dark-blue,    transparent, $dark-blue);    }
.panel-light-brown-outline  { @include cs-panel-variant($light-brown,  transparent, $light-brown,  transparent, $light-brown);  }
.panel-medium-brown-outline { @include cs-panel-variant($medium-brown, transparent, $medium-brown, transparent, $medium-brown); }
.panel-dark-brown-outline   { @include cs-panel-variant($dark-brown,   transparent, $dark-brown,   transparent, $dark-brown);   }
.panel-white-outline        { @include cs-panel-variant(white,         transparent, white,         transparent, white);         }

//==============================================================================
// Variations
//==============================================================================

//----------------------------------------
// Reduced padding & line height
//----------------------------------------

$panel-heading-condensed-padding: 14px 20px;
$panel-body-condensed-padding:    18px 20px;

.panel-condensed {
    line-height: 1.25;

    .panel-heading {
        padding: $panel-heading-condensed-padding;
    }

    .panel-body {
        padding: $panel-body-condensed-padding;
    }
}

//----------------------------------------
// Fully-clickable panels
//----------------------------------------

.panel-clickable {
    a {
        display: block;
        font-weight: inherit;
    }

    .panel-heading {
        padding: 0;

        a {
            padding: $panel-heading-padding;
        }
    }

    &.panel-condensed .panel-heading a {
        padding: $panel-heading-condensed-padding;
    }

    .panel-body {
        padding: 0;

        a {
            padding: $panel-body-padding;
        }
    }

    &.panel-condensed .panel-body a {
        padding: $panel-body-condensed-padding;
    }
}

//----------------------------------------
// Links list
//----------------------------------------

.panel-links .panel-body {
    font-size: 20px;
}

.panel-links ul {
    @extend .list-unstyled;
    margin-bottom: 0;
}

.panel-links a {
    // These are bold in the design, but look better not bold:
    font-weight: inherit;
}

//----------------------------------------
// Tactical box (single link)
//----------------------------------------

.panel-tactical .panel-body {
    font-size: 20px;
}

.panel-tactical-text {
    display: block;
    // This is bold in the design, but looks better not bold:
    // font-weight: bold;
    line-height: 1.4;
    // Note: Needs to be padding-top not margin-top to work with .panel-clickable:
    padding-top: 10px;
    // This could be margin or padding, but let's be consistent:
    padding-bottom: 12px;
}

.panel-tactical-icon {
    display: block;
    font-size: 34px;
    padding-top: 8px;
}

.panel-tactical-icon i {
    display: block;
}
