//==============================================================================
// Links - Used for News & Events
//==============================================================================

$media-list-links-min-height: 42px;

.media-list-links {
    .media {
        font-weight: bold;
        line-height: 1.2;
    }

    .media-body {
        height: $media-list-links-min-height; // Acts as min-height due to "display: table-call"
        vertical-align: middle;
    }

    .media a {
        color: $light-blue;
    }

    .media a:hover,
    .media a:focus {
        color: $light-blue-hover;
    }

    .date-icon {
        min-height: $media-list-links-min-height;
    }

    a.date-icon {
        color: white; // Undo colour in ".media a"
    }
}
