//==============================================================================
// Line under headings
//==============================================================================

.underlined {
    position: relative;
}

.underlined-text {
    background: $light-grey;
    padding-right: 6px;
}

.underlined-line {
    background: $dark-blue;
    bottom: 0;
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
}

//--------------------------------------
// Line up the bottom of the line with the bottom of the text
//--------------------------------------

@mixin xs-underlined-line($heading, $bottom) {
    // e.g. "h1, .h1"
    #{$heading}, .#{$heading} {
        .underlined-line {
            bottom: $bottom;
        }
    }
}

@include xs-underlined-line(h1, 8px);
@include xs-underlined-line(h2, 6px);
@include xs-underlined-line(h3, 5px);
@include xs-underlined-line(h4, 4px);
@include xs-underlined-line(h5, 3px);
@include xs-underlined-line(h6, 3px);
