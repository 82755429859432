// http://webaim.org/techniques/skipnav/
.screen-reader-shortcut {
    // Hidden link
    @extend .sr-only;
    // Unless the user focuses it with the keyboard (by pressing tab)
    @extend .sr-only-focusable;

    &:active,
    &:focus {
        background: white;
        border: 2px solid $oxford-blue;
        left: 5px;
        padding: 4px 8px;
        position: absolute;
        text-decoration: none;
        top: 5px;
    }
}
