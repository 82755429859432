//--------------------------------------
// Clear
//--------------------------------------

.clear { clear: both; }

@media (max-width: $screen-xs-max) {
    .clear-xs { clear: both; }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .clear-sm { clear: both; }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .clear-md { clear: both; }
}

@media (min-width: $screen-lg-min) {
    .clear-lg { clear: both; }
}

//--------------------------------------
// No bold
//--------------------------------------

// For use on links, panel headings, etc. which are bold by default
.no-bold {
    font-weight: normal;
}

//--------------------------------------
// Plain text links
//--------------------------------------

.plain-text {
    color: inherit;
    font-weight: inherit;
}

//--------------------------------------
// Margin/padding overrides
//--------------------------------------

@mixin cs-margin-padding-overrides($suffix: '') {
    .no-margin#{$suffix} {
        margin: 0 !important;
    }

    .no-top-margin#{$suffix} {
        margin-top: 0 !important;
    }

    .no-bottom-margin#{$suffix} {
        margin-bottom: 0 !important;
    }

    .no-padding#{$suffix} {
        padding: 0 !important;
    }

    .no-top-padding#{$suffix} {
        padding-top: 0 !important;
    }

    .no-bottom-padding#{$suffix} {
        padding-bottom: 0 !important;
    }
}

@include cs-margin-padding-overrides;

@media (max-width: $screen-xs-max) {
    @include cs-margin-padding-overrides(-xs);
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @include cs-margin-padding-overrides(-sm);
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @include cs-margin-padding-overrides(-md);
}

@media (min-width: $screen-lg-min) {
    @include cs-margin-padding-overrides(-lg);
}

//--------------------------------------
// Underbar
//--------------------------------------

.underbar {
    background: #fff;
    display: block;
    height: 2px;
    margin: 6px auto 0 auto;
    max-width: 90%;
    opacity: 0.35;
    width: 130px;
}

//--------------------------------------
// Pull left/right with margin
//--------------------------------------

// These are modifiers - add them to pull-left / pull-right
.pull-left-margin {
    margin-bottom: 0.7em;
    margin-right: 1em;
}

.pull-right-margin {
    margin-bottom: 0.7em;
    margin-left: 1em;
}

//--------------------------------------
// Block
//--------------------------------------

.block {
    display: block;
}

//--------------------------------------
// Squared buttons/inputs
//--------------------------------------

.squared {
    // No rounded edges
    border-radius: 0 !important;
}

//--------------------------------------
// Overflow ellipsis
//--------------------------------------

// Add this to sections of text that are too wide for the screen / panel - it
// will cut off the text and add "..." at the end.
.overflow-ellipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
}
