.dropdown-menu > .active > a {
    font-weight: bold;
}

.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    // Use the standard hover effect - default is no hover effect
    background-color: $dropdown-link-hover-bg;
    color: $dropdown-link-hover-color;
}
