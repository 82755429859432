.site-header {
    background: $oxford-blue;
    padding-bottom: 15px;
    padding-top: 15px;
}

.site-header-content {
    min-height: 110px; // Need space for icons in mobile
    position: relative;
}

//--------------------------------------
// Logo (left)
//--------------------------------------

.site-header-logo {
    float: left;
    margin-right: 70px; // Space for icons
}

.site-header-logo h1 {
    // <h1> is used on the homepage only, for 'AA' accessibility compliance
    margin: 0;
}

.site-header-logo a {
    color: white;
    text-decoration: none;
}

.site-header-logo img {
    // Style for Firefox before image loads
    font-size: 24px;
    font-weight: normal;
    white-space: pre;

    &::first-line {
        font-weight: bold;
    }
}

//--------------------------------------
// Icons (right)
//--------------------------------------

.site-header-icons {
    bottom: 0;
    position: absolute;
    right: 15px;
    top: 0;
}

.site-header-icons a {
    @include cs-button-variant(white, null, $medium-brown);
    @include cs-button-hover(white, null, null);
    float: left;
    clear: both;
}

.site-header-icons a ~ a {
    margin-top: 20px;
}

@media (min-width: 510px) {
    .site-header-icons {
        padding-top: 33px;
    }

    .site-header-icons a {
        clear: none;
    }

    .site-header-icons a ~ a {
        margin-left: 20px;
        margin-top: 0;
    }
}

//--------------------------------------
// Tooltips
//--------------------------------------

.site-header .tooltip-inner {
    background: white;
    color: $light-blue;
}

.site-header .left .tooltip-arrow {
    border-left-color: white;
}

//--------------------------------------
// Search popup
//--------------------------------------

.site-header-search {
    background: white;
    border: 2px solid #002147;
    display: none;
    margin-left: 14px; //
    padding-left: 0px;
    position: absolute;
    right: 14px;
    top: 114px;
    z-index: 1001; // .navbar-static-top has a z-index of 1000

    @media (min-width: 510px) {
        top: 82px;
    }
}

.site-header-search:before {
    // Arrow
    border-color: white transparent;
    border-style: solid;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width;
    content: '';
    height: 0;
    position: absolute;
    right: 18px;
    top: -$tooltip-arrow-width;
    width: 0;
}

.site-header-search-form {
    background: white;
    padding: 5px;
}

.site-header-search-type {
    font-size: 20px;
    margin-left: 8px;
}

.site-header-search-type input[type="radio"] {
    margin-top: 8px;
}

// Mobile - multiple lines, left-aligned labels, full width inputs
@media (max-width: $screen-xs-max) {
    .site-header-search .form-group {
        margin-bottom: 5px;
    }

    .site-header-search .form-group:last-child {
        margin-bottom: 0;
    }

    .site-header-search-type {
        margin-top: -2px;
        margin-bottom: 8px;
    }

    .site-header-search .control-label {
        display: none;
    }
}

// Desktop - one line, right-aligned
@media (min-width: $screen-sm-min) {
    .site-header-search {
        text-align: right;
    }

    .site-header-search-type {
        margin-left: 13px;
        margin-right: 13px;
    }

    .site-header-search-type .radio-inline + .radio-inline {
        margin-left: 8px; // Reduce from 10px
    }
}
