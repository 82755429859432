$slideshow-controls-height: 80px;
$slideshow-pager-padding: 20px;
$slideshow-pager-size: 14px;

//--------------------------------------
// Container
//--------------------------------------

.slideshow .bx-wrapper {
    padding-bottom: $slideshow-pager-padding + $slideshow-pager-size;
    position: relative;
}

.slideshow-list {
    @extend .list-unstyled;
    background: $dark-brown;
}

//--------------------------------------
// Slides
//--------------------------------------

.slideshow-slide {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include background-size-polyfill;
    display: none;
    min-height: 240px;
    position: relative;

    @media (min-width: $screen-sm-min) {
        min-height: 290px;
    }
}

// Only show the first slide initially
.slideshow-slide:first-child,
.slideshow-enabled .slideshow-slide {
    display: block;
}

//--------------------------------------
// Text
//--------------------------------------

.slideshow-text-wrapper {
    // The colour is set on the wrapper so it can be overridden by
    // .text-oxford-blue (et al) on the .slideshow-text div
    color: white;
    margin: 0 auto;
    max-width: 1120px; // Lines the text up with the logo on desktop
    padding: 20px 35px 75px 35px;

    @media (min-width: $screen-sm-min) {
        // Make space for the Next/Prev links
        padding-left: 105px;
        padding-right: 105px;
    }
}

.slideshow-text {
    font-size: 42px;
    line-height: 1.125;
    max-width: 465px;
    text-shadow: 0 0 5px $dark-blue;

    @media (min-width: $screen-sm-min) {
        font-size: 48px;
    }
}

// Change shadow for other colours of text
.slideshow-text.text-light-blue,
.slideshow-text.text-dark-brown {
    text-shadow: 0 0 5px white;
}

.slideshow-text.text-oxford-blue,
.slideshow-text.text-dark-blue {
    text-shadow: 0 0 5px $light-brown;
}

//--------------------------------------
// Buttons
//--------------------------------------

.slideshow-btn {
    bottom: 0;
    position: absolute;
    text-shadow: none;

    .btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

//--------------------------------------
// Start/Stop
//--------------------------------------
// This is for accessibility
// http://www.sitepoint.com/unbearable-accessible-slideshow/

.bx-controls-auto {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 1;
}

.bx-controls-auto a {
    background: $dark-blue;
    border-radius: 20px;
    color: white;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    height: 24px;
    line-height: 24px;
    text-align: center;
    width: 24px;
}

.bx-controls-auto a:hover,
.bx-controls-auto a:focus {
    background: $dark-blue-hover;
}

.bx-controls-auto .fa-play {
    padding-left: 2px; // Make it look centred
}

//--------------------------------------
// Next/Previous
//--------------------------------------

.slideshow .bx-controls-direction a {
    display: none;
    height: $slideshow-controls-height;
    margin-top: -($slideshow-controls-height + $slideshow-pager-padding + $slideshow-pager-size) / 2;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    width: 50px;
    z-index: 9999;

    @media (min-width: $screen-sm-min) {
        display: block;
    }
}

.slideshow .bx-controls-direction a.disabled {
    display: none;
}

// @media (min-width: $screen-md-min){
//     .slideshow .bx-controls-direction a {
//         display: block;
//     }
// }

.slideshow .bx-prev {
    left: 25px;
    background: url(/img/slideshow-controls.png) no-repeat 0 0;
}

.slideshow .bx-next {
    right: 25px;
    background: url(/img/slideshow-controls.png) no-repeat -50px 0;
}

.slideshow .bx-prev:hover,
.slideshow .bx-prev:focus {
    background-position: 0 -80px;
}

.slideshow .bx-next:hover,
.slideshow .bx-next:focus {
    background-position: -50px -80px;
}

//--------------------------------------
// Pager
//--------------------------------------

.slideshow .bx-pager {
    bottom: 0;
    color: #666;
    font-size: 12px;
    left: 0;
    line-height: 1;
    position: absolute;
    right: 0;
    text-align: center;
}

.slideshow .bx-pager-item {
    display: inline-block;
}

.slideshow .bx-pager-link {
    border: 2px solid $medium-brown;
    border-radius: 7px;
    display: block;
    height: $slideshow-pager-size;
    margin: 0 5px;
    text-indent: -9999px;
    width: $slideshow-pager-size;
}

.slideshow .bx-pager-link:hover,
.slideshow .bx-pager-link:focus {
    border-color: $dark-blue;
}

.slideshow .bx-pager-link.active {
    background: $light-blue;
    border-color: $light-blue;
}

.slideshow .bx-pager-link.active:hover,
.slideshow .bx-pager-link.active:focus {
    background: $dark-blue;
    border-color: $dark-blue;
}
