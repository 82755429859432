.back-to-top {
    @extend .space-above;

    text-align: center;

    a {
        color: $medium-brown;
        display: inline-block;
        font-weight: bold;
        margin: 10px 0 20px 0;
        text-decoration: none;
        text-transform: uppercase;
    }

    a:hover,
    a:focus {
        color: $dark-brown;
    }

    i {
        // To get the arrow to appear above the text
        display: block;
    }
}
