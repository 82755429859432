// Also has classes: .btn .btn-dark-blue .btn-block
.mobile-menu-btn {
    font-size: 18px;
    padding: 8px 13px;
    text-align: left;

    @include cs-button-hover(null, $light-blue);
}

// Also has classes: .dropdown-menu
.mobile-menu-dropdown {
    border: 2px solid white;
    box-shadow: none;
    margin-top: 0;
    padding-bottom: 2px;
    padding-top: 0;
    width: 100%;

    > li > a {
        border-radius: 3px;
        padding: 7px 13px;
        line-height: 1.1;
        white-space: normal;
    }

    .side-menu-heading {
        padding: 7px 13px;
        line-height: 1.1;
    }

    .side-menu-heading:first-child {
        padding-top: 2px;
    }
}

// Hide the placeholder until the JavaScript has run
.mobile-menu-placeholder {
    display: none;
}
