// Some Bootstrap classes that are related:
// - list-unstyled
// - list-inline

//==============================================================================
// Unbulleted list
//==============================================================================

// Example:
// <ul class="list-unbulleted">
//     <li>Text</li>
// </li>

// This is just like .list-unstyled, but has a small margin between rows
.list-unbulleted {
    @extend .list-unstyled;

    > li {
        margin-bottom: 4px;
    }
}

//--------------------------------------
// Variation with spaces between items
//--------------------------------------

// Example:
// <ul class="list-unbulleted list-unbulleted-spaced">
//     <li>
//         <p>Text</p>
//         <p>Text</p>
//     </li>
// </li>

.list-unbulleted-spaced {
    // margin-bottom: 0;

    > li {
        margin-bottom: 1.8em;
    }

    p {
        margin-bottom: 0.7em;
    }
}

//--------------------------------------
// Child element for extra text
//--------------------------------------

// Example:
// <ul class="list-unbulleted">
//     <li>
//         <a href="#">Title</a>
//         <span class="list-unbulleted-extra">Extra text</span>
//     </li>
// </li>

.list-unbulleted-extra {
    margin-left: 24px;
}

//==============================================================================
// Comma-separated
//==============================================================================

// Converts a <ul> or <ol> to a single line of text with commas between items
.list-comma-separated {
    @extend .list-unstyled;
    margin-bottom: 0;

    > li {
        display: inline;
    }

    > li:after {
        content: ', ';
    }

    > li:last-child:after {
        content: '';
    }
}

//==============================================================================
// A-Z list
//==============================================================================

.list-a-z {
    @extend .list-unstyled;
    font-size: 24px;
    cursor: default;

    > li {
        display: inline-block;
        text-align: center;
        // Fixed width ensures the two rows line up nicely
        width: 22px;
    }

    a {
        cursor: pointer;
    }

    .disabled {
        color: $light-brown;
    }
}
