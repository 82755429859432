//==============================================================================
// Common styles
//==============================================================================

.entry-points {
    background: $light-brown;
}

// .entry-point {
// }

.entry-point a {
    color: white;
    display: block;
    text-decoration: none;
}

.entry-point .title {
    display: block;
    font-size: 24px;
    font-weight: bold;
    left: 0;
    line-height: 1.1;
    min-height: 70px;
    padding: 16px 10px 12px 10px;
    text-align: center;
}

.entry-point .subtitle {
    display: block;
    font-size: 16px;
    font-weight: normal;
    margin-top: 3px;
    margin-bottom: 4px;
}

.entry-point .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include background-size-polyfill;
    display: block;
    height: 140px;
    overflow: hidden;
}

//==============================================================================
// Background colours
//==============================================================================

//--------------------------------------
// Blue
//--------------------------------------

.entry-points-blue {
    .entry-point-odd,
    .no-js & .entry-point {
        background: $light-brown;

        .title {
            background: $light-blue;
        }
        a:hover .title,
        a:focus .title {
            background: darken($light-blue, 10%); // Red doesn't work well here
        }
    }

    .entry-point-even,
    .no-js & .entry-point:nth-child(even) {
        background: $medium-brown;

        .title {
            background: $dark-blue;
        }
        a:hover .title,
        a:focus .title {
            background: darken($dark-blue, 10%);
        }
    }
}

//--------------------------------------
// Brown
//--------------------------------------

.entry-points-brown {
    .entry-point-odd,
    .no-js & .entry-point {
        background: $light-brown;

        .title {
            background: $dark-brown;
        }
        a {
            color: white;
        }
        a:hover .title,
        a:focus .title {
            background: darken($dark-brown, 15%);
        }
    }

    .entry-point-even,
    .no-js & .entry-point:nth-child(even) {
        background: $medium-brown;

        .title {
            background: white;
        }
        a {
            color: $dark-brown;
        }
        a:hover .title,
        a:focus .title {
            background: $white-hover;
            color: darken($dark-brown, 10%);
        }
        .underbar {
            background: $dark-brown;
        }
    }
}
