blockquote {
    border-left-color: $oxford-blue;
}

//--------------------------------------
// Large quote
//--------------------------------------

.quote-lg {
    border-left: none;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.125;
    padding: 0;
}

.quote-lg footer {
    color: inherit;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.125;
}

.quote-lg footer:before {
    // Remove the "-" prefix that Bootstrap adds
    content: none;
}

//--------------------------------------
// Thin
//--------------------------------------

.quote-thin {
    margin-left: auto;
    margin-right: auto;
    max-width: 650px;
}
