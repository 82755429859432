//==============================================================================
// Common
//==============================================================================

.navbar {
    a {
        // Remove the bold we added to all links
        font-weight: normal;
    }
}

.navbar-default {
    //--------------------------------------
    // Increase font size
    //--------------------------------------

    font-size: 18px;

    //--------------------------------------
    // Style the toggle buttons blue instead of grey
    //--------------------------------------

    .navbar-toggle {
        color: $light-blue;
    }

    .navbar-toggle .icon-bar {
        background-color: $light-blue;
        height: 3px;
    }

    .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 3px;
    }

    .navbar-toggle:hover,
    .navbar-toggle:focus {
        background: transparent;
        color: $dark-blue;

        .icon-bar {
            background-color: $dark-blue;
        }
    }
}

//==============================================================================
// Primary navigation
//==============================================================================

.navbar-primary {
    //--------------------------------------
    // Remove the border & padding
    //--------------------------------------

    @extend .navbar-static-top;

    //--------------------------------------
    // Uppercase
    //--------------------------------------

    // The designs say we should do this, but I hate it! -Dave
    // text-transform: uppercase;

    //--------------------------------------
    // Add a margin above
    //--------------------------------------

    margin-top: 10px;

    @media (min-width: $grid-float-breakpoint) {
        margin-top: 30px;
    }

    //--------------------------------------
    // Reduce the minimum height
    //--------------------------------------

    // To accommodate a smaller mobile toggle button
    min-height: 43px;

    //--------------------------------------
    // Style the toggle button
    //--------------------------------------

    // Note: This *adds* to the customisations in .navbar-default above
    .navbar-toggle {
        border: none;
        border-radius: 0;
        float: none;
        margin: 0;
        text-align: right;
        text-transform: uppercase;
    }

    .navbar-toggle .icon {
        display: inline-block;
    }

    .navbar-toggle .icon-bar {
        margin-left: 4px;
        width: 16px;
    }

    //--------------------------------------
    // Centre the links on screen
    //--------------------------------------

    @media (min-width: $grid-float-breakpoint) {
        .navbar-nav {
            float: none;
            text-align: center;
        }

        .navbar-nav > li {
            display: inline-block;
            float: none;
        }
    }

    //--------------------------------------
    // Make the active link bold
    //--------------------------------------

    .navbar-nav > .active > a {
        font-weight: bold;
    }

    //--------------------------------------
    // Add a border below the links
    //--------------------------------------

    border-bottom-width: 4px;

    @media (min-width: $grid-float-breakpoint) {
        // Using an image so it repeats when wrapping to multiple lines
        // TODO: Inline the image with Compass for speed?
        background-image: url(/img/nav-bg.gif);
        border-width: 0;

        .navbar-nav > li > a {
            border-bottom: 4px solid transparent;
            line-height: 38px;
            white-space: nowrap;

            &:hover,
            &:focus {
                border-bottom-color: $navbar-default-link-hover-color;
            }
        }

        .navbar-nav > .active > a {
            &,
            &:hover,
            &:focus {
                border-bottom-color: $navbar-default-link-active-color;
            }
        }

        .navbar-nav > .disabled > a {
            &,
            &:hover,
            &:focus {
                border-bottom-color: transparent;
            }
        }
    }
}
